import {SERVER_BASE_URL} from '@wix/wix-events-commons-statics'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useVisibilityStyles} from '../../../../hooks/use-visibility-styles'
import s from './map.scss'
import {MapProps} from './index'

export const Map = ({mobile, coordinates, language, instance}: MapProps) => {
  const {
    allBreakpoints: {isMapVisible},
  } = useVisibilityStyles()

  if (!coordinates || !isMapVisible()) {
    return null
  }

  return (
    <div id="event-details-page-map" className={mobile ? s.mobileMap : s.map} data-hook={DH.MAP}>
      <iframe
        src={`${SERVER_BASE_URL}/html/google-map?locale=${language}&lat=${coordinates.lat}&lng=${coordinates.lng}&instance=${instance}`}
        className={s.iframe}
        allowFullScreen
        title="Map"
        loading="lazy"
      />
    </div>
  )
}
